import React, { FC } from "react";
import { OctaveOptions } from "../models/customTypes";

type OctaveOffsetProps = {
  offset: number;
  onIncrement: () => void;
  onDecrement: () => void;
  numOctaves: OctaveOptions;
};

const OctaveOffsetButtons: FC<OctaveOffsetProps> = ({
  offset,
  onIncrement,
  onDecrement,
  numOctaves,
}) => {
  const getOctaveOffsetBySize = (): number => {
    if (numOctaves === 6) return 1;

    return 0;
  };

  return (
    <div className="octave-offset-buttons">
      <span>Octave: </span>
      <button
        className="btn btn-light btn-extra-small"
        onClick={onIncrement}
        disabled={offset >= 3 + getOctaveOffsetBySize()}
      >
        Up
      </button>
      <button
        className="btn btn-light btn-extra-small"
        onClick={onDecrement}
        disabled={offset <= 1}
      >
        Down
      </button>
    </div>
  );
};

export default OctaveOffsetButtons;
