import React, { useState } from "react";

type ColorPickerProps = {
  onSelect: (color: string) => void;
};

const colors = [
  { name: "Bright Neon Cyan", code: "#00FFFF" },
  { name: "Bright Neon Green", code: "#00FF00" },
  { name: "Bright Neon Pink", code: "#FF00FF" },
  { name: "Bright Orange", code: "#FFA500" },
  { name: "Bright Neon Yellow", code: "#FFFF00" },
];

const ColorPicker: React.FC<ColorPickerProps> = ({ onSelect }) => {
  const [selectedColor, setSelectedColor] = useState<string>("");

  const handleSelect = (color: string) => {
    setSelectedColor(color);
    onSelect(color);
  };

  return (
    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
      <label htmlFor="color-picker-input" style={{ marginRight: "-5px" }}>
        Key Down Color:
      </label>
      <input
        type="color"
        value={selectedColor}
        onChange={(e) => handleSelect(e.target.value)}
        style={{
          width: "24px",
          height: "24px",
          border:
            selectedColor !==
            colors.find((color) => color.code === selectedColor)?.code
              ? "3px solid #000"
              : "1px solid #000",
          cursor: "pointer",
          marginLeft: "0px",
          transform:
            selectedColor !==
            colors.find((color) => color.code === selectedColor)?.code
              ? "scale(1.1)"
              : "scale(1)",
          transition: "transform 0.2s, border 0.2s",
        }}
      />
      {colors.map((color, index) => (
        <div
          key={index}
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: color.code,
            border:
              selectedColor === color.code
                ? "3px solid #000"
                : "1px solid #000",
            cursor: "pointer",
            transform: selectedColor === color.code ? "scale(1.1)" : "scale(1)",
            transition: "transform 0.2s, border 0.2s",
          }}
          title={color.name}
          onClick={() => handleSelect(color.code)}
        ></div>
      ))}
    </div>
  );
};

export default ColorPicker;
