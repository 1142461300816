import React, { useState, useEffect, useRef, FC } from "react";
import VideoSourceDropdown from "../components/VideoSourceDropdown"; // Import the new component
import "./InstaKeys.scss";
import AudioSourceDropdown from "../components/AudioSourceDropdown";
import PianoCanvas from "../core/PianoCanvas";
import ColorPicker from "../components/ColorPicker";
import OctaveOffsetButtons from "../core/OctaveOffsetButtons";
import PianoPositionControl from "../core/PianoPositionControl";
import UserGuide from "../helpers/userGuide";
import { inject } from "@vercel/analytics";
import OctaveRangeDropdown from "../components/SelectOctaveRangeDropdown";
import { OctaveOptions } from "../models/customTypes";
import { MidiProvider } from "../contexts/MidiContext";

inject();

const InstaKeys: FC = () => {
  /* Master Canvas stuff */
  const DEFAULT_OCTAVE_OFFSET = 0;
  const masterCanvasHeight = 1350;
  const masterCanvasWidth = 1080;
  const masterCanvasRef = useRef<HTMLCanvasElement | null>(null);

  /* Video stuff */
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [recording, setRecording] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<BlobPart[]>([]);
  const [hasMediaPermission, setHasMediaPermission] = useState<boolean>(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const requestAnimationRef = useRef<number>();

  /* Piano stuff */
  const pianoRef = useRef<HTMLCanvasElement | null>(null);
  const [userPianoHeight, setUserPianoHeight] = useState<number>(-575);
  const [pressedKeyColor, setPressedKeyColor] = useState("#00FF00"); // default to Bright Neon Green
  const [octaveOffset, setOctaveOffset] = useState<number>(
    DEFAULT_OCTAVE_OFFSET
  );
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [octaveRange, setOctaveRange] = useState<OctaveOptions>(6);

  /* Piano Settings */
  const [chordsEnabled, setChordsEnabled] = useState<boolean>(false);

  /* Settings stuff */
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState<string | null>(
    null
  );
  const [selectedAudioDevice, setSelectedAudioDevice] = useState<string | null>(
    null
  );

  const startRecording = () => {
    // Clear previous recorded chunks
    setIsDrawing(true);
    setRecordedChunks([]);

    const videoStream = videoRef.current?.srcObject as MediaStream | undefined;

    const stream = videoRef.current?.srcObject as MediaStream | undefined;
    const masterCanvasStream = masterCanvasRef.current?.captureStream(60); // e.g., 30 FPS

    if (videoStream && masterCanvasStream) {
      const audioTrack = videoStream.getAudioTracks()[0];
      masterCanvasStream.addTrack(audioTrack);
    }

    if (!masterCanvasStream) return;

    if (!stream) {
      console.error("Stream is not available");
      return;
    }

    mediaRecorderRef.current = new MediaRecorder(masterCanvasStream);

    mediaRecorderRef.current.ondataavailable = handleDataAvailable;
    mediaRecorderRef.current.start();

    mediaRecorderRef.current.onerror = (event) => {
      console.error("MediaRecorder error:", event);
    };

    mediaRecorderRef.current.onstop = () => {
      console.log("MediaRecorder stopped.");
    };

    // Here we need to draw to the master canvas to merge the piano and video.
    drawPianoAndVideoToMasterCanvas();

    setRecording(true);
  };

  const drawPianoAndVideoToMasterCanvas = () => {
    const masterCtx = masterCanvasRef.current?.getContext("2d");
    if (masterCtx && pianoRef.current && videoRef.current && recording) {
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;
      const videoAspectRatio = videoWidth / videoHeight;

      const canvasWidth = masterCanvasWidth;
      const canvasHeight = masterCanvasHeight;

      // Fit video by height
      const drawHeight = canvasHeight; // Make the video take the full canvas height
      const drawWidth = canvasHeight * videoAspectRatio; // Scale width proportionally

      // Calculate offset to center the video horizontally
      const videoXOffset = (canvasWidth - drawWidth) / 2;

      // Draw the video centered horizontally
      masterCtx.drawImage(
        videoRef.current,
        videoXOffset,
        0, // Video starts at the top
        drawWidth,
        drawHeight
      );

      const pianoHeight = pianoRef.current.height;

      // Calculate remaining space below the video
      const remainingSpaceBelow = canvasHeight - drawHeight;

      // Center the piano in the remaining space below the video
      const pianoYPosition =
        drawHeight + (remainingSpaceBelow - pianoHeight) / 2 - 700;

      /*
        NOTES FOR DRAWING:

        +25 is the bottom of the video.  
        -1225 is the top of the video (This is because the entire video is 1250 height)
      */
      masterCtx.drawImage(
        pianoRef.current,
        0,
        pianoYPosition + 25 - userPianoHeight * 2,
        canvasWidth,
        pianoHeight + 50
      );

      requestAnimationRef.current = requestAnimationFrame(
        drawPianoAndVideoToMasterCanvas
      );
    }
  };

  const handleDataAvailable = (event: BlobEvent) => {
    if (event.data.size > 0) {
      setRecordedChunks((prev) => prev.concat(event.data));
    }
  };

  const download = () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, { type: "video/webm" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = url;
      a.download = "recorded-video.webm";
      a.click();
      URL.revokeObjectURL(url);
    }
  };

  const handleOctaveIncrement = () => {
    setOctaveOffset((prev) => prev + 1);
  };

  const handleOctaveDecrement = () => {
    setOctaveOffset((prev) => prev - 1);
  };

  useEffect(() => {
    handleOctaveIncrement(); // TODO: Remove this after octave rewrite.
  }, []);

  useEffect(() => {
    if (isDrawing) {
      drawPianoAndVideoToMasterCanvas();
    }
  }, [isDrawing]); // Only re-run the effect if isDrawing changes

  useEffect(() => {
    async function getInitialStreamAndDevices() {
      try {
        // Get a stream to prompt for permission
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });

        // Stop the stream immediately since we just want permission
        stream.getTracks().forEach((track) => track.stop());

        // Now enumerate devices since permission has been granted
        const deviceInfos = await navigator.mediaDevices.enumerateDevices();

        const videoDeviceInfos = deviceInfos.filter(
          (device) => device.kind === "videoinput"
        );
        const audioDeviceInfos = deviceInfos.filter(
          (device) => device.kind === "audioinput"
        );

        setHasMediaPermission(true);

        setVideoDevices(videoDeviceInfos);
        setAudioDevices(audioDeviceInfos);

        if (videoDeviceInfos.length > 0) {
          setSelectedVideoDevice(videoDeviceInfos[0].deviceId);
        }

        if (audioDeviceInfos.length > 0) {
          setSelectedAudioDevice(audioDeviceInfos[0].deviceId);
        }
      } catch (err) {
        console.error("Error accessing media devices:", err);
      }
    }

    getInitialStreamAndDevices();
  }, []);

  useEffect(() => {
    if (selectedVideoDevice || selectedAudioDevice) {
      const constraints = {
        video: selectedVideoDevice
          ? {
              deviceId: selectedVideoDevice,
              width: 1080,
              height: 1350,
            }
          : false,
        audio: selectedAudioDevice
          ? {
              deviceId: selectedAudioDevice,
              noiseSuppression: false,
            }
          : false,
      };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch((error) => {
          console.error("Error accessing media devices:", error);
        });
    }
  }, [selectedVideoDevice, selectedAudioDevice]);

  function stopRecording() {
    setIsDrawing(false);
    mediaRecorderRef?.current?.stop();
    setRecording(false);

    if (requestAnimationRef.current)
      cancelAnimationFrame(requestAnimationRef.current);
  }

  return (
    <div className="App">
      <div className="main-container">
        <div className="video-container">
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            width="540"
            height="675"
          ></video>

          <div className="piano" style={{ bottom: `${userPianoHeight}px` }}>
            <MidiProvider>
              <PianoCanvas
                numOctaves={octaveRange}
                videoWidth={540}
                canvasRef={pianoRef}
                pressedKeyColor={pressedKeyColor}
                octaveOffset={octaveOffset}
                chordsEnabled={chordsEnabled}
              />
            </MidiProvider>
          </div>

          <div className="recording">
            <button
              className={`btn ${
                recording ? "btn-warning" : "btn-success"
              } btn-sm`}
              onClick={recording ? stopRecording : startRecording}
            >
              {recording ? "Stop Recording" : "Start Recording"}
            </button>

            {recordedChunks.length > 0 && (
              <button className="btn btn-light btn-sm" onClick={download}>
                Download Recorded Video
              </button>
            )}
          </div>
        </div>
        {hasMediaPermission && (
          <div className="settings">
            <h2 style={{ marginTop: 0 }}>Piano Settings</h2>
            <PianoPositionControl
              octaves={octaveRange as OctaveOptions}
              recording={recording}
              userPianoHeight={userPianoHeight}
              setUserPianoHeight={setUserPianoHeight}
              chordsEnabled={chordsEnabled}
            />
            <div>
              <OctaveOffsetButtons
                offset={octaveOffset}
                onIncrement={handleOctaveIncrement}
                onDecrement={handleOctaveDecrement}
                numOctaves={6}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ColorPicker onSelect={setPressedKeyColor} />
            </div>

            <div>
              <label className="form-check-label" htmlFor="customCheck1">
                Display Chords:
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={chordsEnabled}
                onChange={(e) => setChordsEnabled(e.target.checked)}
                id="customCheck1"
              />
            </div>

            {chordsEnabled && (
              <span> Chords will display under the piano </span>
            )}

            <OctaveRangeDropdown
              octaves={[3, 5, 6]}
              selectedOctave={octaveRange}
              onSelect={(octave) => setOctaveRange(octave as OctaveOptions)}
            ></OctaveRangeDropdown>

            <h2 style={{ marginTop: "10px" }}>Video / Audio Settings</h2>
            <VideoSourceDropdown
              videoDevices={videoDevices}
              selectedVideoDevice={selectedVideoDevice}
              onSelect={(deviceId) => setSelectedVideoDevice(deviceId)}
            />
            <AudioSourceDropdown
              audioDevices={audioDevices}
              selectedAudioDevice={selectedAudioDevice}
              onSelect={(deviceId) => setSelectedAudioDevice(deviceId)}
            />
            <UserGuide />
          </div>
        )}

        <canvas
          ref={masterCanvasRef}
          width={1080}
          height={1350}
          style={{ display: "none" }} // Hide it from the user
        ></canvas>
      </div>
    </div>
  );
};

export default InstaKeys;
