import React, { FC } from "react";
import "./VideoSourceDropdown.scss";

type VideoSourceDropdownProps = {
  videoDevices: MediaDeviceInfo[];
  selectedVideoDevice: string | null;
  onSelect: (deviceId: string) => void;
};

const VideoSourceDropdown: FC<VideoSourceDropdownProps> = ({
  videoDevices,
  selectedVideoDevice,
  onSelect,
}) => {
  if (videoDevices.length === 0) return null;

  return (
    <div>
      <label>Video Source: </label>
      <select
        className="btn btn-light btn-extra-small"
        value={selectedVideoDevice ?? ""}
        onChange={(e) => onSelect(e.target.value)}
      >
        {videoDevices.map((device, index) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Video Source ${index + 1}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default VideoSourceDropdown;
