import React from "react";
import "./userGuide.scss"; // Make sure to compile SCSS to CSS

const UserGuide = () => {
  return (
    <div className="user-guide">
      <h1>Welcome to InstaMusicTools</h1>

      <ol className="steps">
        <li>
          <strong>Browser Compatibility:</strong> Use Google Chrome or Firefox.
          This app does not work on mobile.{" "}
        </li>
        <li>
          <strong>MIDI:</strong> This application uses Midi. You need to enable
          Midi in your browser. You need to connect a Midi cable.
        </li>
        <li>
          <strong>Device Recognition:</strong> If your device isn&apos;t
          recognized immediately, try refreshing this site or restarting your
          browser.
        </li>
        <li>
          <strong>Permissions:</strong> Our app will request access to your
          camera and microphones and midi devices. No video or audio data is
          transmitted to InstaMusicTools.
        </li>
        <li>
          <strong>Recording:</strong> Hit &quot;Start Recording&quot; to begin
          and &quot;Stop Recording&quot; to end the session.
        </li>
        <li>
          <strong>Download:</strong> You can download your video in
          &quot;.webm&quot; format after stopping the recording.
        </li>
        <li>
          <strong>File Conversion:</strong> Visit{" "}
          <a
            href="https://cloudconvert.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            cloudconvert.com
          </a>{" "}
          to convert your file to &quot;.mp4&quot; if needed. This site also
          offers free conversion:{" "}
          <a
            href="https://www.freeconvert.com/webm-to-mp4"
            target="_blank"
            rel="noopener noreferrer"
          >
            freeconvert.com
          </a>
        </li>

        <p>Created by J. A. Fritzel, 2023. Copyright 2023.</p>
      </ol>
    </div>
  );
};

export default UserGuide;
