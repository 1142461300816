import React, { FC, useEffect, useState } from "react";
import { getNoteName } from "../helpers/midiHelpers";
import { Chord } from "tonal";
import { Midi } from "tonal";
import { OctaveOptions } from "../models/customTypes";
import { useMidi } from "../contexts/MidiContext"; // Make sure this path is correct

import "./PianoCanvas.scss";

type PianoCanvasProps = {
  videoWidth: number;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  pressedKeyColor?: string;
  numOctaves: OctaveOptions;
  octaveOffset: number;
  chordsEnabled: boolean;
};

const PianoCanvas: FC<PianoCanvasProps> = ({
  videoWidth,
  canvasRef,
  pressedKeyColor,
  numOctaves,
  octaveOffset,
  chordsEnabled,
}) => {
  const defaultPressedColor = pressedKeyColor || "#00FF00";

  const totalWhiteKeys = 52; // 52 white keys in an 88 key keyboard.  Although my rendered keyboard is not quite 88 keys, keeping this for now.

  const pianoYPos = 0; // Y Position of the piano on the canvas

  const [whiteKeyHeight, setWhiteKeyHeight] = useState<number>(112);

  const [whiteKeyWidth, setWhiteKeyWidth] = useState<number>(25);

  const { noteHash } = useMidi(); // Access noteHash directly from the context

  /* These mappers can be generated using helpers/midiHelpers.ts */
  const whiteNotesMapper = [
    0, 2, 4, 5, 7, 9, 11, 12, 14, 16, 17, 19, 21, 23, 24, 26, 28, 29, 31, 33,
    35, 36, 38, 40, 41, 43, 45, 47, 48, 50, 52, 53, 55, 57, 59, 60, 62, 64, 65,
    67, 69, 71, 72, 74, 76, 77, 79, 81, 83, 84, 86, 88, 89, 91, 93, 95, 96, 98,
    100, 101, 103, 105, 107, 108, 110, 112, 113, 115, 117, 119, 120, 122, 124,
    125, 127,
  ];

  const blackNotesMapper = [
    1, 3, 6, 8, 10, 13, 15, 18, 20, 22, 25, 27, 30, 32, 34, 37, 39, 42, 44, 46,
    49, 51, 54, 56, 58, 61, 63, 66, 68, 70, 73, 75, 78, 80, 82, 85, 87, 90, 92,
    94, 97, 99, 102, 104, 106, 109, 111, 114, 116, 118, 121, 123, 126,
  ];

  /* These have to assume a width */
  useEffect(() => {
    switch (numOctaves) {
      case 1:
        setWhiteKeyHeight(82);
        setWhiteKeyWidth(22);
        break;
      case 3:
        setWhiteKeyHeight(190);
        setWhiteKeyWidth(49.4);
        break;
      case 5:
        setWhiteKeyHeight(135);
        setWhiteKeyWidth(30);
        break;
      case 6:
        setWhiteKeyHeight(112);
        setWhiteKeyWidth(25.2);
        break;
    }
  }, [numOctaves]);

  const adjustNoteByOctave = (note: number): number => {
    return note + (octaveOffset - 1) * 12;
  };

  const getNoteLabel = (midiNote: number): string | null => {
    const noteIndex = midiNote % 12;
    const octave = Math.floor(midiNote / 12) - 1;

    // Only label 'C' notes which have a noteIndex of 0
    if (noteIndex === 0) {
      return `C${octave}`;
    }
    return null; // Don't label any other notes
  };

  /* Font size and position offset for the white-key labels C1, C2, C3 etc when the user changes piano size */
  const getFontSizeAndOffset = (octaves: number): [string, number] => {
    switch (octaves) {
      case 3:
        return ["20", 20];
      case 5:
        return ["16", 13];
      case 6:
        return ["12", 12];
      default:
        return ["12", 12];
    }
  };

  const drawWhiteKey = (
    ctx: CanvasRenderingContext2D,
    i: number,
    yOffset: number,
    whiteKeyWidth: number,
    whiteKeyHeight: number,
    fontSize: string,
    offset: number,
    noteHash: Record<number, boolean>,
    cornerRadius: number // Added corner radius parameter
  ) => {
    const x = i * whiteKeyWidth;
    const midiNote = adjustNoteByOctave(whiteNotesMapper[i]);

    // Define the bottom corners with some radius
    const bottomLeftCornerX = x;
    const bottomLeftCornerY = yOffset + whiteKeyHeight - cornerRadius;

    const bottomRightCornerX = x + whiteKeyWidth;
    const bottomRightCornerY = yOffset + whiteKeyHeight - cornerRadius;

    // Start the path for the white key shape
    ctx.beginPath();
    ctx.moveTo(x, yOffset); // Move to the top-left corner
    ctx.lineTo(x + whiteKeyWidth, yOffset); // Draw line to the top-right corner
    ctx.lineTo(x + whiteKeyWidth, bottomRightCornerY); // Draw line to the start of bottom-right corner curve

    // Create the rounded corner on the bottom-right
    ctx.arcTo(
      bottomRightCornerX,
      yOffset + whiteKeyHeight,
      bottomRightCornerX - cornerRadius,
      yOffset + whiteKeyHeight,
      cornerRadius
    );

    // Draw line to the start of bottom-left corner curve
    ctx.lineTo(bottomLeftCornerX + cornerRadius, yOffset + whiteKeyHeight);

    // Create the rounded corner on the bottom-left
    ctx.arcTo(
      bottomLeftCornerX,
      yOffset + whiteKeyHeight,
      bottomLeftCornerX,
      bottomLeftCornerY,
      cornerRadius
    );

    // Draw line up to the top-left corner to close the path
    ctx.lineTo(x, yOffset);

    ctx.closePath(); // Close the path back to the top-left corner

    // Fill the key
    ctx.fillStyle = noteHash[midiNote]
      ? pressedKeyColor || defaultPressedColor
      : "#F5F2E4";
    ctx.fill();

    // Stroke the key border
    ctx.strokeStyle = "black";
    ctx.stroke();

    // Draw the key label
    const label = getNoteLabel(midiNote);
    if (label) {
      ctx.fillStyle = "#787276";
      ctx.font = `${fontSize}px Tahoma`;
      const labelWidth = ctx.measureText(label).width;
      ctx.fillText(
        label,
        x + (whiteKeyWidth - labelWidth) / 2,
        yOffset + whiteKeyHeight - offset
      );
    }
  };

  // Black keys do not sit perfect in the center of white keys. We need to calculate the offset which depends on the black note.
  const getBlackKeyOffsets = (midiNote: number) => {
    const noteName = getNoteName(midiNote);

    switch (noteName) {
      case "C#":
        return -2.2;
      case "D#":
        return 2.2;
      case "F#":
        return -2.2;
      case "A#":
        return 2.2;
      default:
        return 0;
    }
  };

  const drawBlackKey = (
    ctx: CanvasRenderingContext2D,
    i: number,
    yOffset: number,
    whiteKeyWidth: number,
    blackKeyWidth: number,
    blackKeyHeight: number,
    midiNote: number,
    noteHash: Record<number, boolean>,
    cornerRadius: number // Added corner radius parameter
  ) => {
    const offset = getBlackKeyOffsets(midiNote);

    // Calculate the position of the black key
    const x = (i + 1) * whiteKeyWidth - blackKeyWidth / 2 + offset;

    // Define the bottom corners with some radius
    const bottomLeftCornerX = x;
    const bottomLeftCornerY = yOffset + blackKeyHeight - cornerRadius;

    const bottomRightCornerX = x + blackKeyWidth;
    const bottomRightCornerY = yOffset + blackKeyHeight - cornerRadius;

    // Begin the path for the black key shape
    ctx.beginPath();
    ctx.moveTo(x, yOffset); // Move to the top-left corner of the black key
    ctx.lineTo(x + blackKeyWidth, yOffset); // Draw line to the top-right corner
    ctx.lineTo(x + blackKeyWidth, bottomRightCornerY); // Draw line to the start of bottom-right corner curve

    // Create the rounded corner on the bottom-right
    ctx.arcTo(
      bottomRightCornerX,
      yOffset + blackKeyHeight,
      bottomRightCornerX - cornerRadius,
      yOffset + blackKeyHeight,
      cornerRadius
    );

    // Draw line to the start of bottom-left corner curve
    ctx.lineTo(bottomLeftCornerX + cornerRadius, yOffset + blackKeyHeight);

    // Create the rounded corner on the bottom-left
    ctx.arcTo(
      bottomLeftCornerX,
      yOffset + blackKeyHeight,
      bottomLeftCornerX,
      bottomLeftCornerY,
      cornerRadius
    );

    // Draw line up to the top-left corner to close the path
    ctx.lineTo(x, yOffset);

    ctx.closePath();

    // Fill the key with black color as the base
    ctx.fillStyle = noteHash[midiNote]
      ? pressedKeyColor || defaultPressedColor
      : "#202020";
    ctx.fill();

    // Create the glossy gradient effect
    const gradient = ctx.createLinearGradient(
      x,
      yOffset,
      x,
      yOffset + blackKeyHeight / 2
    );
    gradient.addColorStop(0, "rgba(255, 255, 255, 0.4)"); // Slight gloss at the top
    gradient.addColorStop(1, "rgba(0, 0, 0, 0)"); // Transition to fully transparent

    // Apply the glossy gradient on top of the base color
    ctx.fillStyle = gradient;
    ctx.fill();

    // Optionally draw a border around the key
    ctx.strokeStyle = "black";
    ctx.stroke();
  };

  const drawPiano = () => {
    if (canvasRef.current) {
      canvasRef.current.width = 1080; // Set the drawing buffer size
      canvasRef.current.height = 1350; // Set the drawing buffer size
      canvasRef.current.style.width = videoWidth + "px"; // Display size width
      canvasRef.current.style.height = "675px"; // Display size height

      const ctx = canvasRef.current.getContext("2d")!;

      const blackKeyWidth = whiteKeyWidth / 2;
      const blackKeyHeight = whiteKeyHeight * 0.6;
      const [fontSize, offset] = getFontSizeAndOffset(numOctaves);

      // Draw white keys
      for (let i = 0; i < totalWhiteKeys; i++) {
        drawWhiteKey(
          ctx,
          i,
          pianoYPos,
          whiteKeyWidth,
          whiteKeyHeight,
          fontSize,
          offset,
          noteHash,
          2.5
        );
      }

      // Adjust black notes once before drawing
      const adjustedBlackNotesMapper = blackNotesMapper.map(adjustNoteByOctave);

      // Draw black keys
      const blackKeyPattern = [1, 1, 0, 1, 1, 1, 0];
      let blackNoteCounter = 0;
      for (
        let i = 0;
        i < totalWhiteKeys &&
        blackNoteCounter < adjustedBlackNotesMapper.length;
        i++
      ) {
        if (blackKeyPattern[i % 7]) {
          drawBlackKey(
            ctx,
            i,
            pianoYPos,
            whiteKeyWidth,
            blackKeyWidth,
            blackKeyHeight,
            adjustedBlackNotesMapper[blackNoteCounter],
            noteHash,
            1.5
          );
          blackNoteCounter++;
        }
      }
    }
  };

  function drawChordText(currentChord: string, ctx: CanvasRenderingContext2D) {
    // Text properties
    const text = currentChord;
    const textSize = 45;
    ctx.font = `bold ${textSize}px Times`;

    // Measure text
    const textMetrics = ctx.measureText(text);
    const textWidth = textMetrics.width;
    const padding = 17; // Padding around the text

    // Rectangle dimensions and position
    const rectX = 500 - padding / 2; // Adjust based on padding
    const rectY = 195 - textSize - padding / 2; // Adjust based on text size and padding
    const rectWidth = textWidth + padding;
    const rectHeight = textSize + padding;

    let chordPadding = 0;
    switch (numOctaves) {
      case 3: {
        chordPadding += 70;
        break;
      }
      case 5: {
        chordPadding += 50;
        break;
      }
      case 6: {
        chordPadding += 25;
        break;
      }
      default:
        break;
    }

    // Draw rectangle
    ctx.fillStyle = "#8f8f8f"; // Rectangle color (can be different from text color)
    ctx.fillRect(rectX, rectY + chordPadding, rectWidth, rectHeight + 10);

    // Draw text on top
    ctx.fillStyle = "white"; // Text color
    ctx.fillText(text, 500, rectY + 50 + chordPadding);
  }

  function getCurrentNotesPressed() {
    return Object.keys(noteHash)
      .filter((key) => noteHash[parseInt(key, 10)] === true)
      .map((key) => parseInt(key, 10));
  }

  useEffect(() => {
    drawPiano();
  }, [noteHash, whiteKeyHeight, whiteKeyWidth, numOctaves, octaveOffset]);

  /* This effect is for drawing chord text on screen */
  useEffect(() => {
    if (!chordsEnabled) return;

    const currentMidiNotesPressed = getCurrentNotesPressed();

    if (!currentMidiNotesPressed || currentMidiNotesPressed.length < 3) return;

    const currentNotesNamesPressed = currentMidiNotesPressed.map((midiNum) =>
      Midi.midiToNoteName(midiNum)
    );

    const currentChord = Chord.detect(currentNotesNamesPressed, {
      assumePerfectFifth: true,
    });

    if (!canvasRef.current || !currentChord.length || currentChord[0] === "")
      return;

    const ctx = canvasRef.current.getContext("2d")!;

    drawChordText(currentChord.length ? currentChord[0] : "", ctx);
  }, [noteHash]);

  return (
    <div>
      <canvas
        className="piano-canvas"
        ref={canvasRef}
        width={videoWidth}
        height={100}
      ></canvas>
    </div>
  );
};

export default PianoCanvas;
