import { FC, useEffect, useState } from "react";
import { inject } from "@vercel/analytics";
import React from "react";
import { Outlet } from "react-router-dom";
import "./App.scss"; // Make sure this points to your stylesheet

inject();

const App: FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // This function checks the window width to determine if the device is mobile
    const checkMobile = () => {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (width < 768) {
        // You can adjust this value based on your needs
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Call checkMobile on initial load and window resize
    checkMobile();
    window.addEventListener("resize", checkMobile);

    // Cleanup event listener
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <div>
      {isMobile ? (
        <div className="show-on-mobile">Please use in web 🙂</div>
      ) : (
        <div className="hide-on-mobile">
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default App;
