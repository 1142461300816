import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import InstaKeys from "./core/InstaKeys";
import Layout from "./components/Layout";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <App />
      </Layout>
    ),
    children: [
      { index: true, element: <Navigate to="instakeys" replace /> },
      { path: "instakeys", element: <InstaKeys /> },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    {" "}
    {/* Wrap the router provider with AuthServiceProvider */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
