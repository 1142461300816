import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";

interface MidiContextType {
  midiAccess: WebMidi.MIDIAccess | null;
  noteHash: Record<number, boolean>;
}

// Provide a default context value that matches the expected structure
const defaultMidiContext: MidiContextType = {
  midiAccess: null,
  noteHash: {},
};

// Create a context with a default empty value.
const MidiContext = createContext<MidiContextType>(defaultMidiContext);

// Custom hook to use the context
export const useMidi = () => {
  const context = useContext(MidiContext);
  if (context === undefined) {
    throw new Error("useMidi must be used within a MidiProvider");
  }
  return context;
};

interface MidiProviderProps {
  children: ReactNode;
}

export const MidiProvider: React.FC<MidiProviderProps> = ({ children }) => {
  const [midiAccess, setMidiAccess] = useState<WebMidi.MIDIAccess | null>(null);
  const [noteHash, setNoteHash] = useState<Record<number, boolean>>({});

  useEffect(() => {
    const onMIDISuccess = (midiAccess: WebMidi.MIDIAccess) => {
      setMidiAccess(midiAccess);
      midiAccess.inputs.forEach((input) => {
        input.onmidimessage = onMIDIMessage;
      });
    };

    const onMIDIFailure = () => {
      console.warn("Could not access your MIDI devices.");
    };

    const onMIDIMessage = (message: WebMidi.MIDIMessageEvent) => {
      const [status, note, velocity] = Array.from(message.data);
      const isNoteOnMessage = status === 144 && velocity > 0;
      const isNoteOffMessage =
        status === 128 || (status === 144 && velocity === 0);

      if (isNoteOnMessage || isNoteOffMessage) {
        setNoteHash((prevNoteHash) => {
          return { ...prevNoteHash, [note]: isNoteOnMessage };
        });
      }
    };

    if (navigator.requestMIDIAccess) {
      navigator.requestMIDIAccess().then(onMIDISuccess, onMIDIFailure);
    } else {
      console.warn("WebMIDI is not supported in this browser!");
    }
  }, []);

  const contextValue = {
    noteHash,
    midiAccess,
  };

  return (
    <MidiContext.Provider value={contextValue}>{children}</MidiContext.Provider>
  );
};
