import React, { FC } from "react";

type SelectOctaveRangeDropdownProps = {
  octaves: number[];
  selectedOctave: number;
  onSelect: (octave: number) => void;
};

const OctaveRangeDropdown: FC<SelectOctaveRangeDropdownProps> = ({
  octaves,
  selectedOctave,
  onSelect,
}) => {
  return (
    <div>
      <label>Octaves: </label>
      <select
        className="btn btn-light btn-extra-small"
        value={selectedOctave ?? ""}
        onChange={(e) => onSelect(parseInt(e.target.value))}
      >
        {octaves.map((num) => (
          <option key={num} value={num}>
            {num}
          </option>
        ))}
      </select>
    </div>
  );
};

export default OctaveRangeDropdown;
