/* For generating midi mappings which help with drawing rectangles correctly */
export const generateWhiteNotesMapper = (): number[] => {
  const whiteNotesPattern = [0, 2, 4, 5, 7, 9, 11]; // C, D, E, F, G, A, B pattern
  const whiteNotes = [];
  for (let i = 0; i <= 127; i++) {
    if (whiteNotesPattern.includes(i % 12)) {
      whiteNotes.push(i);
    }
  }
  return whiteNotes;
};

export const generateBlackNotesMapper = (): number[] => {
  const blackNotesPattern = [1, 3, 6, 8, 10]; // C#, D#, F#, G#, A# pattern
  const blackNotes = [];
  for (let i = 0; i <= 127; i++) {
    if (blackNotesPattern.includes(i % 12)) {
      blackNotes.push(i);
    }
  }
  return blackNotes;
};

/* Converts a midi-number to a note, ie C, C#, D, etc.  Does not specify octave ie C1, C#3, etc  */
export const getNoteName = (midiNumber: number) => {
  const noteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  const noteIndex = midiNumber % 12;
  const noteName = noteNames[noteIndex];
  
  return noteName;
};
